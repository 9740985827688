<template>
  <div class="main" :style="{height: $store.state.menuFlag?'100%':'calc(100% - 70px)'}">
    <div class="box">
      <div class="content">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="问卷查询导出" name="first">
            <p class="p-header">
              <span></span>
              <span>筛选条件</span>
            </p>
            <div class="header">
              <div class="div1">
                <span>项目:</span>
                <!--                <el-select v-model="value1" placeholder="请选择" @change="ProjectChange">-->
                <!--                  <el-option v-for="(item, index) in Project" :key="index" :label="item.Name" :value="item.Id">-->
                <!--                  </el-option>-->
                <!--                </el-select>-->

                <Cascader :value.sync="value1" :options="Project" @MyChange="ProjectChange"
                          :multiple="false"></Cascader>

              </div>
              <div class="div1">
                <span>CRF表:</span>
                <el-select ref="multiSelect" :loading="loading" value-key="Id" @remove-tag="RemoveTag" v-model="value2"
                           placeholder="请选择" multiple collapse-tags @change="CRFChange"
                           @visible-change="visibleCRFChange">
                  <el-option v-for="(item, index) in CRFSurface" :key="index" :label="item.Name"
                             :value="item">
                  </el-option>
                </el-select>
              </div>
              <div class="div1">
                <span>入组时间:</span>
                <el-select v-model="Group" clearable placeholder="请选择" @change="GroupChange">
                  <el-option v-for="(item, index) in options" :key="index" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div v-show="Group != ''">
                <el-date-picker v-model="DateTime" type="date" placeholder="选择日期" @change="DateTimechange"
                                value-format="yyyy-MM-dd">
                </el-date-picker>
              </div>
              <div class="div1">
                <span>随访时间:</span>
                <el-date-picker v-model="startDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
                                @change="startDateChange">
                </el-date-picker>
                <span style="width: 30px">至</span>
                <el-date-picker v-model="endDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
                                @change="endDateChange">
                </el-date-picker>
              </div>
              <div class="div1">
                <span>访视次数:</span>
                <el-input style="width: 216px" v-model="Visit" placeholder="请输入内容"
                          @change="VisitChange"></el-input>
              </div>
              <div>
                <span>关键字:</span>
                <el-input style="width: 216px" v-model="Keyword" placeholder="请输入内容" @change="KeywordChange"
                          @blur="blurKeyInput"></el-input>
              </div>
              <div>
                <span>数据类型:</span>
                <el-select v-model="exportType" placeholder="请选择" @change="changeExportType">
                  <el-option label="excel" :value="0"/>
                  <el-option label="sav" :value="1"/>
                </el-select>
              </div>
              <!-- <div style="display: flex;align-items: center;margin-left: 10px;">
                            <el-button type="primary" icon="el-icon-search">查询</el-button>
                            <el-button type="primary" icon="el-icon-upload2"  style="background:#FF9900; border: 1px solid #FF9900;">导出</el-button>
                      </div> -->
            </div>
            <!-- :class="{myFrame:value2.length==0?true:false}" -->
            <div style="height: 620px" @click="myFrame" class="iframeId">
              <iframe :class="{ myFrame: i == 0 ? true : false }" frameborder="0" name="myFrame" ref="myFrame"
                      scrolling="yes" width="100%" height="100%" :src="Url"></iframe>
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="问卷数据统计" name="third">
            <DataBoard />
          </el-tab-pane> -->
          <el-tab-pane label="患者查询导出" name="second">
            <div class="query_patient">
              <div style="margin-bottom: 10px" class="patient">
                <span class="span_lable">项目:</span>
                <!--                <el-select v-model="PatentListform.ProjectId" placeholder="请选择">-->
                <!--                  <el-option v-for="(item, index) in PatentProject" :key="index" :label="item.Name" :value="item.Id">-->
                <!--                  </el-option>-->
                <!--                </el-select>-->
                <Cascader :value.sync="PatentListform.ProjectId" :options="PatentProject" :multiple="false"></Cascader>

                <span class="span_lable">患者:</span>
                <el-input v-model="PatentListform.Name" placeholder="请输入内容"></el-input>
                <span class="span_lable">性别:</span>
                <el-select v-model="PatentListform.Sex" placeholder="请选择" value-key="id">
                  <el-option v-for="(item, index) in options1" :key="index" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
                <el-button @click="PatientQuery" type="primary" style="margin-left: 10px"><i
                    class="el-icon-search"></i>查询
                </el-button>
              </div>
              <div style="margin-bottom: 10px" class="patient">
                <span class="span_lable">出生日期:</span>
                <el-date-picker v-model="PatentListform.Birthday" type="date" placeholder="选择日期"
                                format="yyyy-MM-dd">
                </el-date-picker>
                <span class="span_lable">就诊日期:</span>
                <el-date-picker v-model="PatentListform.VisitDate" type="date" placeholder="选择日期"
                                format="yyyy-MM-dd">
                </el-date-picker>
                <span class="span_lable">出院日期:</span>
                <el-date-picker v-model="PatentListform.DischargeDate" type="date" placeholder="选择日期"
                                format="yyyy-MM-dd">
                </el-date-picker>
                <el-button @click="PatientExport" type="primary" style="margin-left: 10px"><i
                    class="el-icon-upload2"></i>导出
                </el-button>
              </div>
              <div>
                <el-table :data="ProjectTable" style="width: 98%; margin: 0 auto" height="550px" :header-cell-style="{
                  'text-align': 'center',
                  background: 'rgb(240, 247, 253)'
                }" border>
                  <!-- @select-all="selectAll"
                              @selection-change="handleActivitySelectionChange" -->
                  <el-table-column type="selection" min-width="30" align="center">
                  </el-table-column>
                  <el-table-column label="风险" min-width="48" align="center">
                    <template slot-scope="scope">
                      <el-tag :color="scope.row.RiskRating == '01' ||
                        scope.row.RiskRating == null
                        ? '#3fbc39'
                        : scope.row.RiskRating == '02'
                          ? '#f6d10e'
                          : scope.row.RiskRating == '03'
                            ? '#fd9133'
                            : scope.row.RiskRating == '04'
                              ? '#ff3f3f'
                              : ''
                        " effect="dark" disable-transitions></el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column prop="Name" label="姓名" min-width="70" align="center" :sortable="true"
                                   :sort-method="sortByName">
                  </el-table-column>
                  <el-table-column prop="Tel" label="电话" min-width="120" align="center">
                  </el-table-column>
                  <el-table-column label="性别" min-width="48" align="center">
                    <template slot-scope="scope">
                      <p>{{ scope.row.Sex == 1 ? '男' : '' }}</p>
                      <p>{{ scope.row.Sex == 0 ? '女' : '' }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="Age" label="年龄" min-width="60" align="center" :sortable="true">
                  </el-table-column>

                  <el-table-column label="出生日期" min-width="50" align="center" :sortable="true">
                    <template #default="{ row }">
                      {{ row.Birthday?.split(" ")[0] }}
                    </template>
                  </el-table-column>

                  <el-table-column label="就诊日期" min-width="50" align="center" :sortable="true">
                    <template #default="{ row }">
                      {{ row.VisitDate?.split(" ")[0] }}
                    </template>
                  </el-table-column>

                  <el-table-column prop="DischargeDate" label="出院日期" min-width="50" align="center" :sortable="true">
                    <template #default="{ row }">
                      {{ row.DischargeDate?.split(" ")[0] }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="OperTime" label="创建时间" min-width="120" align="center" :sortable="true">
                    <template #default="{ row }">
                      {{ row.CreateTime }}
                    </template>
                  </el-table-column>
                  <el-table-column label="患者标签" min-width="100" align="center">
                    <template slot-scope="scope">
                      <span v-for="(item, index) in scope.row.LabelNameList" :key="index" class="tag_span">
                        {{ item }}
                      </span>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop="status" label="随访详情" min-width="80" align="center">
                                <template slot-scope="scope">
                                  <el-link :underline="false" @click="FollowUpRecords(scope.row)"
                                  >随访记录</el-link
                                  >
                                </template>
                              </el-table-column>
                              <el-table-column prop="patient" label="患者画像" width="90" align="center">
                                <template slot-scope="scope">
                                  <img  @click="PatientPortrait(scope.row)"
                                        src="../../assets/image/icon/patient-touxiang.png" alt=""
                                  >
                                </template
                                >
                              </el-table-column>
                              <el-table-column prop="records" label="填写问卷" min-width="90" align="center">
                                <template slot-scope="scope">
                                  <el-link :underline="false" @click="QuestionnaireFilling(scope.row)"
                                  >问卷填写</el-link>
                                </template>
                              </el-table-column>
                              <el-table-column label="操作" width="145"  class="edit-info">
                                <template slot-scope="scope">
                                  <div style="display: flex;justify-content: center">
                                    <el-link @click="AddPatientDialog(scope.row)" type="primary" :underline="false"><i class="el-icon-edit-outline"></i>修改</el-link>
                                    <el-link
                                      :underline="false"
                                      style="margin-left: 11px"
                                      @click="DeleteProjectPatient(scope.row)"
                                    ><i class="el-icon-delete"></i>删除</el-link
                                    >
                                  </div>
                                </template>
                              </el-table-column>
                              <el-table-column label="人工外呼" width="205" align="center">
                                <template slot-scope="scope">
                                  <div style="display: flex;justify-content: center">
                                    <button @click="OneTouchCall(scope.row)" class="yijian" style="margin-right: 10px;">
                                      <img src="../../assets/image/icon/phone.png" style="vertical-align: middle;">
                                      <span style="vertical-align: middle;">一键呼叫</span>
                                    </button>
                                    <el-link  :underline="false" @click="History(scope.row)">
                                      <img src="../../assets/image/icon/search.png"  style="vertical-align: middle">历史
                                    </el-link>
                                  </div>
                                </template>
                              </el-table-column> -->
                </el-table>
                <Pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.rows"
                            @pagination="ProjectGetPageList"></Pagination>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import derive from '../../api/derive'
import ProjectManagement from '../../api/ProjectManagement'
import Pagination from '../../components/Pagination'
import {local} from "../../utils/storage";
import Cascader from "@/components/Cascader.vue";
// import DataBoard from "./DataBoard.vue"

export default {
  name: 'derive',
  data() {
    return {
      DateTime: '',
      startDate: '',
      endDate: '',
      options: [
        {
          value: '00',
          label: '就诊日期'
        },
        {
          value: '01',
          label: '出院日期'
        }
      ],
      ProjectTable: [],
      PatentListform: {
        ProjectId: ["ALL"],
        Name: '',
        Sex: null,
        Birthday: '',
        VisitDate: '',
        DischargeDate: ''
      },
      ProjectId: '',
      options1: [
        {
          value: null,
          label: '全部'
        },
        {
          value: "",
          label: '不详'
        },
        {
          value: '1',
          label: '男'
        },
        {
          value: '0',
          label: '女'
        }
      ],
      loading: false,
      activeName: 'first',
      Group: '', //入组时间
      followUp: '', //随访时间
      Keyword: '', //关键字
      Visit: null,
      value1: null,
      value2: [],
      Project: [],
      Url: '',
      CRFSurface: [],
      PatientTable: [],
      list: [],
      total: 0,
      listQuery: {
        Key: '',
        page: 1,
        rows: 10
      },
      PatentProject: [{Name: "全部", Id: "ALL", ParentId: null}],
      i: 1,
      RoleType: local.get("RoleType"),
      exportType: 0, // 导出类型
    };
  },
  components: {
    Cascader,
    Pagination
  },
  created() {
    this.ProjectList()
    this.QuestionnaireToken()
    this.$route.query.name && (this.activeName = this.$route.query.name)
  },
  beforeRouteUpdate(to, from, next) {
    to.query.name && (this.activeName = to.query.name)
    next()
  },
  methods: {
    // 切换导出类型的回调
    changeExportType() {
      let params = {
        type: 7,
        exportType: this.exportType
      }
      this.$refs.myFrame.contentWindow.postMessage(params, '*')
    },
    sortByName(obj1, obj2) {
      if (
          [obj1.Name, obj2.Name][0] ==
          [obj1.Name, obj2.Name].sort((a, b) =>
              a.localeCompare(b, 'zh', {sensitivity: 'accent'})
          )[0]
      ) {
        return -1
      } else {
        return 1
      }
    },
    //时间转化函数
    timeTrans(time, type) {
      if (!time) return ''
      let date = new Date(new Date(time).getTime() + 8 * 3600 * 1000)
      date = date.toJSON()
      date = date.substring(0, 10)
      return date
    },
    GroupChange() {
      this.DateTime = ''
    },
    myFrame() {
      console.log(this.i)
      this.$refs.multiSelect.blur()
      this.i = 1
    },
    handleClick(val) {
      console.log(val.index)
      if (val.index == '1') {
        this.ProjectGetPageList()
      }
    },
    PatientQuery() {
      this.ProjectGetPageList()
    },
    //项目患者列表
    async ProjectGetPageList() {
      const parameter = {
        ProjectId: this.PatentListform.ProjectId.slice(-1),
        Key: this.listQuery.Key,
        page: this.listQuery.page,
        rows: this.listQuery.rows,
        Key: this.PatentListform.Name,
        Sex: this.PatentListform.Sex,
        // Birthday:  this.PatentListform.Birthday,
        // VisitDate: this.PatentListform.VisitDate,
        // DischargeDate: this.PatentListform.DischargeDate,
        Birthday: this.timeTrans(this.PatentListform.Birthday),
        VisitDate: this.timeTrans(this.PatentListform.VisitDate),
        DischargeDate: this.timeTrans(this.PatentListform.DischargeDate)
      }
      if (this.PatentListform.Sex === null) {
        delete parameter.Sex
      }
      // console.log(parameter, '参数');
      // return
      ProjectManagement.ProjectGetPageListData(parameter).then((res) => {
        console.log(res.data);
        if (res.data.Status == 1) {
          this.total = res.data.Data.total
          this.ProjectTable = res.data.Data.rows
        } else {
          alert(res.data.Message)
        }
      })
    },
    downloadAudioStream(data, name) {
      // ArrayBuffer 转为 Blob
      const blob = new Blob([data], {type: 'application/vnd.ms-excel'})
      const objectUrl = URL.createObjectURL(blob)
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.style.display = 'none'
      a.setAttribute('href', objectUrl)
      a.setAttribute('target', '_blank')
      a.setAttribute('download', name + '.' + 'xls')
      a.click()
      URL.revokeObjectURL(objectUrl)
      document.body.removeChild(a)
    },
    //患者查询导出
    PatientExport() {
      if (this.ProjectTable.length != 0) {
        derive.PatientExportData(this.PatentListform).then((res) => {
          console.log(res)
          this.downloadAudioStream(res.data, '数据导出')
        })
      } else {
        alert('没有数据')
      }
    },
    //获取问卷token
    async QuestionnaireToken() {
      derive.QuestionnaireTokenData().then((res) => {
        if (res.data.Status == 1) {
          // this.Url = `${window.global_config.BASE_URL1}#/QueryExport?type=1&access_token=${res.data.Data.access_token}&refresh_token=${res.data.Data.refresh_token}`
          this.Url = `${res.data.Data.Url}/#/QueryExport?type=1&access_token=${res.data.Data.access_token}&refresh_token=${res.data.Data.refresh_token}`
          // this.Url = `${window.global_config.BASE_URL1}/#/QueryExport?type=1&access_token=${res.data.Data.access_token}&refresh_token=${res.data.Data.refresh_token}`
        } else {
          alert(res.data.Message)
        }
      })
    },
    //项目
    async ProjectList() {
      derive.ProjectListData().then((res) => {
        if (res.data.Status == 1) {
          this.Project = res.data.Data
          console.log(res.data.Data)
          this.PatentProject.push(...res.data.Data)
        } else {
          alert(res.data.Message)
        }
        console.log(res)
      })
    },
    RemoveTag(tag) {
      console.log(tag)
      tag['type'] = 5
      this.$refs.myFrame.contentWindow.postMessage(tag, '*')
    },
    //问卷关闭下拉回调
    visibleCRFChange(e) {
      if (e == false) {
        this.$refs.myFrame.contentWindow.postMessage(this.list, '*')
        this.i = 1
      } else {
        this.i = 0
      }
    },
    //访视次数回调
    VisitChange(e) {
      let sty = /[^0-9]/g
      if (sty.test(e)) {
        this.Visit = ''
        this.$message.error('请输入数字')
      } else {
        let objVisit = {
          type: 1,
          num: e
        }
        this.$refs.myFrame.contentWindow.postMessage(objVisit, '*')
      }
    },
    //关键字回调
    KeywordChange(e) {
      // let objKeyword = {
      //   type: 2,
      //   num: e,
      // };
      // this.$refs.myFrame.contentWindow.postMessage(objKeyword, "*");
    },
    //前端关键字查询
    blurKeyInput(e) {
      if (e.target.value == '' && this.DateTime == '') {
        let objGroup = {
          type: 8,
          num: [],
        }
        this.$refs.myFrame.contentWindow.postMessage(objGroup, '*');
        return;
      }
      ;
      this.PatientGetIdListData();
    },
    //请求患者id数组列表[]
    PatientGetIdListData() {
      let params = {};
      if (this.Group == "00") {
        params = {
          ProjectId: this.value1,
          VisitDate: this.DateTime,
          Key: this.Keyword
        }
      } else if (this.Group == '01') {
        params = {
          ProjectId: this.value1,
          DischargeDate: this.DateTime,
          Key: this.Keyword
        }
      } else {
        params = {
          ProjectId: this.value1,
          Key: this.Keyword
        };
      }
      ;

      derive.PatientGetIdListData(params).then((res) => {
        console.log("res -->", res)
        if (res.data.Status == 1) {
          let objGroup;
          if (res.data.Data.length == 0) {
            objGroup = {
              type: 8,
              num: null,
            }
          } else {
            objGroup = {
              type: 8,
              num: res.data.Data,
            };
          }
          this.$refs.myFrame.contentWindow.postMessage(objGroup, "*");
          console.log(objGroup, 'objGroup')
        }
      });
    },
    //选择入组时间
    DateTimechange(e) {
      console.log("e -->", e)
      if (e == null && this.Keyword == '') {
        let objGroup = {
          type: 8,
          num: [],
        }
        this.$refs.myFrame.contentWindow.postMessage(objGroup, '*');
        return;
      }
      ;
      this.PatientGetIdListData();
    },
    //开始时间
    startDateChange(e) {
      let objGroup = {
        type: 3,
        num: e
      }
      this.$refs.myFrame.contentWindow.postMessage(objGroup, '*')
    },
    //结束时间
    endDateChange(e) {
      let objfollowUp = {
        type: 4,
        num: e
      }
      console.log(e);
      this.$refs.myFrame.contentWindow.postMessage(objfollowUp, '*')
    },
    //选中问卷列表
    CRFChange(e) {
      console.log(e, '#######')
      this.list = e
    },
    //选中项目出问卷列表
    ProjectChange(e) {
      this.loading = true
      this.value2 = []
      const parameter = {
        ProjectId: [e.slice(-1)[0]],
      }
      derive.CRFSurfaceData(parameter).then((res) => {
        console.log(res)
        if (res.data.Status == 1) {
          this.loading = false
          this.CRFSurface = res.data.Data
        } else {
          alert(res.data.Message)
        }
      });
      //组织管理员则传[]给iframe
      if (this.RoleType != 'D') {
        this.PatientGetIdListData();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$refs.myFrame.contentWindow.postMessage('close', '*')
    setTimeout(() => {
      this.$destroy();
      next();
    }, 200);
  }
};
</script>

<style scoped lang="scss">
@import '../../styles/mixin.scss';

/* chrome & safari 浏览器 */
/*滚动条整体部分,必须要设置*/
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  // background-color: #333;
}

/*滚动条的滑块按钮*/
::-webkit-scrollbar-thumb {
  border-radius: 0;
  // background-color: rgba(128,133,144,.06);
  background: #9AC2EF;
}

/*滚动条的上下两端的按钮*/
::-webkit-scrollbar-button {
  height: 0;
}

.myFrame {
  pointer-events: none;
}

.patient {
  ::v-deep .el-input__inner {
    width: 260px;
  }
}

.span_lable {
  width: 80px;
  display: inline-block;
  text-align: right;
  margin-right: 10px;
}

::v-deep .el-input {
  width: initial;
}

::v-deep .el-tabs__item {
  font-size: 18px;
}

.main {
  width: 100%;
  min-width: 1440px;
  overflow: hidden;

  .box {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    margin: 0 auto;

    // background: #fff;
    .content {
      width: 100%;
      height: 100%;
      margin-top: 20px;
      background: #fff;
      padding: 10px 20px 0 20px;
      box-sizing: border-box;
      overflow: auto;

      ::v-deep .el-button {
        padding-top: 10px;
        height: 34px;
        font-weight: 400;
        @include add-size5($font_size_14);
      }

      .header {
        display: flex;
        flex-wrap: wrap;

        // align-items: center;
        .div1 {
          margin-bottom: 10px;
        }

        span {
          display: inline-block;
          width: 70px;
          // margin-right: 10px;
          margin-left: 10px;
        }
      }

      .content-table {
        display: flex;
        margin-bottom: 10px;

        .column-name {
          width: 43%;
        }

        ul {
          width: 14%;

          li {
            text-align: center;
            margin-bottom: 10px;

            ::v-deep .el-button {
              border: 1px solid #3388ff;
              color: #3388ff;
            }
          }
        }

        .column-find {
          width: 43%;
        }
      }

      .advanced-query-table {
        margin-bottom: 30px;
      }
    }
  }
}

.p-header {
  overflow: hidden;
  margin-bottom: 10px;

  span:nth-child(1) {
    display: inline-block;
    width: 3px;
    height: 16px;
    background: #3388ff;
    margin-right: 10px;
    vertical-align: middle;
  }

  span:nth-child(2) {
    display: inline-block;
    font-weight: 500;
    color: #595d67;
    @include add-size($font_size_16);
    vertical-align: middle;
  }
}

.el-table {
  ::v-deep .el-tag {
    border-radius: 50% !important;
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }

  .tag_span {
    display: inline-block;
    min-width: 80px;
    height: 32px;
    background-color: #409eff;
    border: 1px solid #b3d8ff;
    border-radius: 4px;
    line-height: 32px;
    // color: #409eff;
    color: #fff;
    padding: 0 10px;
    box-sizing: border-box;
    margin-bottom: 8px;
    margin-left: 4px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5) {
  .main {
    min-width: 0 !important;
    // .content{
    //   .el-tabs{
    //     height: 100%;
    //     display: flex;
    //     flex-direction: column;

    //     .el-tabs__content{
    //       flex: 1 !important;

    //       .el-tab-pane{
    //         height: 100%;

    //         ::v-deep .el-table{
    //           height: 100% !important;
    //         }
    //       }
    //     }
    //   }
    // }
    // ::v-deep .el-table{
    //   height: 100% !important;
    // }

    .patient {
      ::v-deep .el-input__inner {
        width: 110px !important;
      }

      ::v-deep .el-input {
        width: 110px !important;
      }

      .span_lable {
        width: 75px !important;
      }
    }
  }
}
</style>
